import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">About Us</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                About Us{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-grids-3 py-3" id="about">
        <div className="container py-md-5 py-3">
          <div className="row bottom-ab-grids align-items-center">
            <div className="col-lg-6 bottom-ab-left">
              <h6 className="sub-titlehny">About Us</h6>
              <h3 className="hny-title">{companyname} Go Beyond</h3>
              <div className="separatorhny" />
              <p className="my-3">
                The company {companyname} is headquartered in India. In the
                Indian market, we are a newly established and rapidly growing
                freight forwarding company. We have consistently exceeded the
                expectations of our domestic clients. Recognizing the pivotal
                role that shipping and transportation play in our daily lives,
                our primary recruitment strategy is to seek proactive
                individuals with expertise in aviation, liner, and freight
                forwarding.
              </p>
             
            </div>
            <div className="col-lg-6 bottom-ab-right mt-lg-0 mt-5 pl-lg-4">
              <img src="assets/images/About.jpg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      {/*//grids*/}
      {/*/features*/}
      <section className="w3l-ab-features">
        <div className="container py-md-5 ">
          <div className="row features-w3pvt-main" id="features">
            <div className="col-lg-4 col-md-6 feature-gird">
              <div className="row features-hny-inner-gd">
                <div className="col-md-2 col-2 featured_grid_left">
                  <div className="icon-hnynumber">
                    <span className="hnynumber">01</span>
                  </div>
                </div>
                <div className="col-md-10 col-10 featured_grid_right_info">
                  <h4>
                    <Link className="link-hny">CLARITY</Link>
                  </h4>
                  <p>
                    Our operational process involves scrutinizing the entire
                    data flow, starting from the product and extending to the
                    customer's final steps.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 feature-gird mt-md-0 mt-4">
              <div className="row features-hny-inner-gd">
                <div className="col-md-2 col-2 featured_grid_left">
                  <div className="icon-hnynumber">
                    <span className="hnynumber">02</span>
                  </div>
                </div>
                <div className="col-md-10 col-10 featured_grid_right_info">
                  <h4>
                    <Link className="link-hny">DILIGENCE</Link>
                  </h4>
                  <p>
                    We proactively identify and address opportunities and
                    challenges, staying ahead of potential issues.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 feature-gird mt-lg-0 mt-md-5 mt-4">
              <div className="row features-hny-inner-gd">
                <div className="col-md-2 col-2 featured_grid_left">
                  <div className="icon-hnynumber">
                    <span className="hnynumber">03</span>
                  </div>
                </div>
                <div className="col-md-10 col-10 featured_grid_right_info">
                  <h4>
                    <Link className="link-hny">VERSATILITY</Link>
                  </h4>
                  <p>
                    Our extensive global network of partners and suppliers
                    allows us to swiftly adapt and navigate various logistical
                    challenges with ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      {/* //blog-Section */}
      {/*//news-grids*/}
      <section className="w3l-news-sec">
        <div className="news-mainhny py-5">
          <div className="container text-center py-lg-3">
            <div className="title-content text-center mb-lg-5 mb-4">
              <h6 className="sub-titlehny">Our Services</h6>
              <h3 className="hny-title">Explore our Services</h3>
              <div className="separatorhny" />
            </div>
          </div>
          <div className=" row p-2  ">
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Ocean">
                  <img
                    src="assets/images/Ocean Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Ocean">
                    <h4 className="title">Ocean Freight</h4>
                  </Link>
                  <Link to="/Ocean" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Air">
                  <img
                    src="assets/images/Air Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Air">
                    <h4 className="title">Air Freight</h4>
                  </Link>
                  <Link to="/Air" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Inland">
                  <img
                    src="assets/images/Inland Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Inland">
                    <h4 className="title">Inland Freight</h4>
                  </Link>
                  <Link to="/Inland" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className=" row p-2 my-4 ">
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Warehousing">
                  <img
                    src="assets/images/Warehousing & Distributions.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Warehousing">
                    <h4 className="title">Warehousing & Distributions</h4>
                  </Link>
                  <Link to="/Warehousing" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Customs">
                  <img
                    src="assets/images/Customs Brokerage.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Customs">
                    <h4 className="title">Customs Brokerage</h4>
                  </Link>
                  <Link to="/Customs" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Value">
                  <img
                    src="assets/images/Value Added Services.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Value">
                    <h4 className="title">Value Added Services</h4>
                  </Link>
                  <Link to="/Value" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
