import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">Customs Brokerage</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                Customs Brokerage{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-11 py-5">
        <div className="text11 py-md-5 py-4">
          <div className="container">
            <div
              style={{
                margin: "8px auto",
                display: "block",
                textAlign: "center",
              }}
            >
              {/*-728x90-*/}
            </div>
            <div className="row">
              <div className="col-lg-8 text11-content">
                <img
                  src="assets/images/Customs Brokerage.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4 className>Customs Brokerage</h4>
                <p className="mt-4 mb-3">
                  <p>
                    Traditions Financier, a division of our group, provides
                    invaluable insights into customs regulations, rules, and
                    guidelines for the smooth handling of imported and exported
                    goods. We also manage the intricate process of import and
                    export documentation, which includes duty and tax
                    calculations, as well as the payment of various fees and
                    charges.
                  </p>

                  <p>
                    While developing a comprehensive transportation system that
                    integrates air, ocean, warehousing, and distribution is one
                    achievement, ensuring the seamless cross-border movement of
                    goods while navigating the intricate landscape of
                    regulations and compliance is another challenge altogether.
                  </p>

                  <p>
                    At {companyname}, our management network is designed to
                    minimize disruptions as much as possible. Through our
                    certified customs brokers and import/export experts, we
                    collaborate closely with customs authorities in each country
                    to expedite shipments at every stage. The ultimate outcome
                    is practical customs management solutions that enable
                    businesses to implement a comprehensive and efficient
                    freight forwarding solution.
                  </p>
                </p>
              </div>
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-5">
                <aside className="sidebar">
                  <div className="sidebar-widget sidebar-blog-category">
                    <div className="sidebar-title">
                      <h4>Other Services</h4>
                    </div>
                    <ul className="blog-cat">
                      <li>
                        <Link to="/Ocean">
                          <span className="fa fa-angle-double-right" /> Ocean
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Air">
                          <span className="fa fa-angle-double-right" /> Air
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Inland">
                          <span className="fa fa-angle-double-right" /> Inland
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing">
                          <span className="fa fa-angle-double-right" />{" "}
                          Warehousing & Distributions <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs">
                          <span className="fa fa-angle-double-right" /> Customs
                          Brokerage <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Value">
                          <span className="fa fa-angle-double-right" /> Value
                          Added Services <label></label>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
