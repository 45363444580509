import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">Warehousing & Distributions</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                Warehousing & Distributions{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-11 py-5">
        <div className="text11 py-md-5 py-4">
          <div className="container">
            <div
              style={{
                margin: "8px auto",
                display: "block",
                textAlign: "center",
              }}
            >
              {/*-728x90-*/}
            </div>
            <div className="row">
              <div className="col-lg-8 text11-content">
                <img
                  src="assets/images/Warehousing & Distributions.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4 className>Warehousing & Distributions</h4>
                <p className="mt-4 mb-3">
                  <p>
                    Efficient storage and warehousing play a crucial role in the
                    supply chain process. {companyname} offers contract
                    warehousing services for the secure storage of your goods on
                    a global scale. Our strategically located facilities near
                    airports and seaports nationwide help reduce freight
                    forwarding costs.
                  </p>

                  <p>
                    We provide cutting-edge solutions for warehousing, including
                    cold storage and packaging. In addition to pre-retailing
                    services, we offer a wide range of value-added options such
                    as atmospheric, chilled, frozen, hazardous, and bonded
                    operations. Our services are designed to streamline delivery
                    times, reduce shipping and storage costs, enhance customer
                    satisfaction, and optimize overall supply chain efficiency.
                  </p>

                  <p>
                    Our expert team at {companyname} employs top-notch storage
                    and warehousing strategies for effective supply chain
                    management, delivering solutions that add value to your
                    organization. Regardless of your industry, we offer
                    flexibility in customer reporting, cost reduction
                    consultancy, professionally managed services, and a complete
                    supply chain solution to ensure you can consistently meet
                    your service commitments to customers nationwide. With{" "}
                    {companyname} providing you with a secure, cost-effective,
                    and efficient warehouse system, you'll have more quality
                    time to focus on your core business.
                  </p>
                </p>
              </div>
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-5">
                <aside className="sidebar">
                  <div className="sidebar-widget sidebar-blog-category">
                    <div className="sidebar-title">
                      <h4>Other Services</h4>
                    </div>
                    <ul className="blog-cat">
                      <li>
                        <Link to="/Ocean">
                          <span className="fa fa-angle-double-right" /> Ocean
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Air">
                          <span className="fa fa-angle-double-right" /> Air
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Inland">
                          <span className="fa fa-angle-double-right" /> Inland
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing">
                          <span className="fa fa-angle-double-right" />{" "}
                          Warehousing & Distributions <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs">
                          <span className="fa fa-angle-double-right" /> Customs
                          Brokerage <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Value">
                          <span className="fa fa-angle-double-right" /> Value
                          Added Services <label></label>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
