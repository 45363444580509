import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">Air Freight</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                Air Freight{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-11 py-5">
        <div className="text11 py-md-5 py-4">
          <div className="container">
            <div
              style={{
                margin: "8px auto",
                display: "block",
                textAlign: "center",
              }}
            >
              {/*-728x90-*/}
            </div>
            <div className="row">
              <div className="col-lg-8 text11-content">
                <img
                  src="assets/images/Air Freight.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4 className>Air Freight</h4>
                <p className="mt-4 mb-3">
                  <p>
                    {companyname} has established crucial strategic partnerships
                    with various airlines, ensuring that we can provide the
                    industry's most competitive rates along with year-round
                    capacity guarantees. This means your cargo can take flight
                    at your convenience. We also offer a comprehensive suite of
                    services, including freight connections, weight insurance,
                    exemptions, door-to-door cargo security, and real-time
                    end-to-end cargo visibility. Thanks to our key airline
                    alliances, we offer a diverse range of customizable pricing
                    and delivery time options, making our integrated air freight
                    solutions available globally to meet all major demands.
                    Contact {companyname} today to explore how our air freight
                    solutions can be tailored to your specific requirements.
                    Sometimes, expanding your horizons can be truly liberating.
                  </p>
                </p>
              </div>
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-5">
                <aside className="sidebar">
                  <div className="sidebar-widget sidebar-blog-category">
                    <div className="sidebar-title">
                      <h4>Other Services</h4>
                    </div>
                    <ul className="blog-cat">
                      <li>
                        <Link to="/Ocean">
                          <span className="fa fa-angle-double-right" /> Ocean
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Air">
                          <span className="fa fa-angle-double-right" /> Air
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Inland">
                          <span className="fa fa-angle-double-right" /> Inland
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing">
                          <span className="fa fa-angle-double-right" />{" "}
                          Warehousing & Distributions <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs">
                          <span className="fa fa-angle-double-right" /> Customs
                          Brokerage <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Value">
                          <span className="fa fa-angle-double-right" /> Value
                          Added Services <label></label>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
