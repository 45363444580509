import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">Contact</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                Contact Us{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/*//w3l-breadcrumb*/}
      <div
        style={{ margin: "8px auto", display: "block", textAlign: "center" }}
      >
        {/*-728x90-*/}
      </div>
      {/*/contact*/}
      <section className="w3l-contact-11 pt-5">
        <div className="contacts-main pt-lg-5 pt-3">
          <div className="title-content text-center">
            <h6 className="sub-titlehny">Contact</h6>
            <h3 className="hny-title mb-0">We’re Here to Help You</h3>
            <p className="mb-md-5 mb-4">
              Have some suggestions or just want to say hi? Contact us:
            </p>
          </div>
          <div className="form-41-mian section-gap mt-5">
            <div className="container">
              <div className="d-grid align-form-map">
                <div className="form-inner-cont">
                  <form
                    id="contactForm"
                    action="/php/thankyou-contact.php "
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="control-group">
                          <input
                            className="form-control border-0 p-4"
                            onChange={(event) => handleFnameChange(event)}
                            type="text"
                            id="fname"
                            name="fname"
                            value={fname}
                            placeholder="Enter your first name"
                            required
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="control-group">
                          <input
                            className="form-control border-0 p-4"
                            onChange={(event) => handlelnameChange(event)}
                            type="text"
                            id="lname"
                            name="lname"
                            value={lname}
                            placeholder="Enter your last name"
                            required
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="control-group">
                          <input
                            className="form-control border-0 p-4"
                            onChange={(event) => handleemailChange(event)}
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            placeholder="Enter your Email"
                            required
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>{" "}
                      <div className="col-12">
                        <div className="control-group">
                          <input
                            className="form-control border-0 p-4"
                            onChange={(event) => handlesubjectChange(event)}
                            type="text"
                            id="subject"
                            name="subject"
                            value={subject}
                            placeholder="Enter subject"
                            required
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="control-group">
                          <textarea
                            className="form-control border-0 p-4"
                            id="message"
                            name="message"
                            rows={1}
                            placeholder="Enter Message"
                            defaultValue={""}
                            value={message}
                            onChange={(event) => handlemessageChange(event)}
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <button
                          className="btn btn-primary py-3 px-5"
                          type="submit"
                          defaultValue=""
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                  <h5 style={{ color: "red" }}>{result}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="contant11-top-bg mt-4">
            <div className="container pb-lg-5">
              <div className="d-grid contact py-lg-5 py-4">
                <div className="contact-info-left d-grid text-left">
                  <div className="contact-info">
                    <span className="fa fa-map-o" aria-hidden="true" />
                    <h4>Visit Us Anytime:</h4>
                    <p className="mb-2">{companyaddress}</p>
                  </div>
                  <div className="contact-info">
                    <span className="fa fa-phone" aria-hidden="true" />
                    <h4>Phone Us 24/7:</h4>
                    <p className="mb-2 conp">
                      {" "}
                      <a>{companynumber}</a>
                    </p>
                  </div>
                  <div className="contact-info">
                    <span className="fa fa-envelope-o" aria-hidden="true" />
                    <h4>Mail Us 24/7:</h4>
                    <p className="mb-2 conp">
                      <a className="email">{companyemail}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map">
           
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7541.423599951685!2d72.99813983692091!3d19.076404860289053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1385adaa917%3A0x36d603953c5c0ec3!2sAPMC%20Market%2C%20Sector%2019%2C%20Vashi%2C%20Navi%20Mumbai%2C%20Maharashtra%20400703!5e0!3m2!1sen!2sin!4v1695806735993!5m2!1sen!2sin"

              frameBorder={0}
              allowFullScreen
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
