import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">Ocean Freight</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                Ocean Freight{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-11 py-5">
        <div className="text11 py-md-5 py-4">
          <div className="container">
            <div
              style={{
                margin: "8px auto",
                display: "block",
                textAlign: "center",
              }}
            >
              {/*-728x90-*/}
            </div>
            <div className="row">
              <div className="col-lg-8 text11-content">
                <img
                  src="assets/images/Ocean Freight.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4 className>Ocean Freight</h4>
                <p className="mt-4 mb-3">
                  <p>
                    Ocean freight has evolved into one of the most
                    cost-effective shipping methods available today. Building a
                    strong partnership with our clients and understanding their
                    shipment volume, lead time, and budgetary considerations are
                    the cornerstones of our approach. Our commitment is to
                    provide the most optimal marine freight solutions while
                    effectively managing our clients' supply chain.
                  </p>

                  <p>
                    Clients who choose {companyname} for their freight
                    forwarding needs will benefit from our comprehensive
                    multi-modal transportation options designed to streamline
                    the flow of goods. This integrated system ensures secure and
                    dependable shipping to and from major ports worldwide, all
                    at competitive rates. {companyname} takes pride in
                    being a trusted ocean freight forwarder, dedicated to
                    facilitating shipments by sea.
                  </p>
                </p>
              </div>
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-5">
                <aside className="sidebar">
                  <div className="sidebar-widget sidebar-blog-category">
                    <div className="sidebar-title">
                      <h4>Other Services</h4>
                    </div>
                    <ul className="blog-cat">
                      <li>
                        <Link to="/Ocean">
                          <span className="fa fa-angle-double-right" /> Ocean
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Air">
                          <span className="fa fa-angle-double-right" /> Air
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Inland">
                          <span className="fa fa-angle-double-right" /> Inland
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing">
                          <span className="fa fa-angle-double-right" />{" "}
                          Warehousing & Distributions <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs">
                          <span className="fa fa-angle-double-right" /> Customs
                          Brokerage <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Value">
                          <span className="fa fa-angle-double-right" /> Value
                          Added Services <label></label>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
