import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      {/*/w3l-subscribe*/}
      <section className="w3l-subscribe-content py-5">
        <div className="container py-md-4 py-3 text-center">
          <div className="row my-lg-4 mt-4">
            <div className="col-lg-9 col-md-10 mx-auto">
              <div className="subscribe mx-auto">
                <div className="header-section text-center mx-auto">
                  <h6 className="sub-titlehny">Newsletter</h6>
                  <h3 className="hny-title">Stay Updated! </h3>
                  <div className="separatorhny" />
                  <p className="my-3">
                    Our newsletter is your direct link to industry experts. Get
                    tips, tricks, and advice from professionals who understand
                    the ins and outs of freight forwarding.
                  </p>
                </div>

                <form
                  action="/php/thankyou-subscribe.php "
                  method="POST"
                  onSubmit={(event) => handleSubmit(event)}
                  className="subscribe-wthree pt-2 mt-5"
                >
                  <div className="d-md-flex flex-wrap subscribe-wthree-field">
                    <input
                      className="form-control"
                      onChange={(event) => handleSubemailChange(event)}
                      id="subemail"
                      value={subemail}
                      type="email"
                      name="subemail"
                      placeholder="Enter your email address"
                      required
                    />
                    <button className="btn btn-style btn-primary" type="submit">
                      Subscribe
                    </button>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="w3l-footer-29-main">
        <div className="footer-29-w3l py-5">
          <div className="container py-lg-4">
            <div className="row footer-top-29">
              <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-1 pr-lg-5">
                <div className="footer-logo mb-3">
                  <Link className="footer-brand-logo" to="/">
                    <img
                      src="assets/images/logo.png"
                      style={{ height: "80px" }}
                    ></img>
                  </Link>
                </div>
                <p>
                  The company Helios Enterprises is headquartered in India. In
                  the Indian market, we are a newly established and rapidly
                  growing freight forwarding company. We have consistently
                  exceeded the expectations of our domestic clients.{" "}
                </p>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-sm-0 mt-5">
                <ul>
                  <h6 className="footer-title-29">Usefull Links</h6>
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li>
                    <Link to="/About">About Company</Link>
                  </li>
                  <li>
                    <Link to="/Services">Services</Link>
                  </li>
                  <li>
                    <Link to="/Trading">Industry solutions</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link  to="#download"
                        data-toggle="modal"
                        data-target="#myModal">Request Quote</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-3 mt-lg-0 mt-5">
                <h6 className="footer-title-29">Services</h6>
                <ul>
                <li> <Link to="/Ocean">Ocean Freight</Link></li>
                <li><Link to="/Air">Air Freight</Link></li>
                <li><Link to="/Inland">Inland Freight</Link></li>
                <li> <Link to="/Warehousing">Warehousing & Distributions</Link></li>
                <li> <Link to="/Customs">Customs Brokerage</Link></li>
                  <li><Link to="/Value">Value Added Services</Link></li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-4 mt-lg-0 mt-5">
                <h6 className="footer-title-29">Contact Infomation</h6>
                
                <p>
                  <strong>Address :</strong> {companyaddress}
                </p>
                <p className="my-2">
                  <strong>Phone :</strong>{" "}
                  <Link >{companynumber}</Link>
                </p>
                <p>
                  <strong>Email :</strong>{" "}
                  <Link>{companyemail}</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* //footer */}
        {/* copyright */}
        <section className="w3l-copyright">
          <div className="container">
            <div className="row bottom-copies">
              <p className="col-lg-8 copy-footer-29">
                © 2023 {companyname}. All rights reserved. 
              </p>
              <div className="col-lg-4 footer-list-29">
                <ul className="d-flex text-lg-right">
                 
                  <li className="mx-lg-5 mx-md-4 mx-3">
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Terms">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* move top */}
        <button onclick="topFunction()" id="movetop" title="Go to top">
          ⤴
        </button>
        {/* /move top */}
      </footer>
    </>
  );
};

export default Footer;
