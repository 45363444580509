import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">Inland Freight</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                Inland Freight{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-text-11 py-5">
        <div className="text11 py-md-5 py-4">
          <div className="container">
            <div
              style={{
                margin: "8px auto",
                display: "block",
                textAlign: "center",
              }}
            >
              {/*-728x90-*/}
            </div>
            <div className="row">
              <div className="col-lg-8 text11-content">
                <img
                  src="assets/images/Inland Freight.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4 className>Inland Freight</h4>
                <p className="mt-4 mb-3">
                  <p>
                    In India, we offer a comprehensive range of transportation
                    solutions, including Full Truck Load (FTL) and Less Than
                    Truck Load (LTL) services. We go above and beyond by
                    providing value-added services such as GPS tracking,
                    temperature-controlled trucks, vehicles equipped with air
                    suspension, and round-the-clock real-time monitoring. Our
                    dedicated team of road freight specialists, with diverse
                    industry experience and expertise, is committed to devising
                    the most efficient road freight solutions tailored to your
                    unique requirements. We excel in crafting optimal and
                    cost-effective shipping strategies for your cargo.
                  </p>
                </p>
              </div>
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12 mt-lg-0 mt-5">
                <aside className="sidebar">
                  <div className="sidebar-widget sidebar-blog-category">
                    <div className="sidebar-title">
                      <h4>Other Services</h4>
                    </div>
                    <ul className="blog-cat">
                      <li>
                        <Link to="/Ocean">
                          <span className="fa fa-angle-double-right" /> Ocean
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Air">
                          <span className="fa fa-angle-double-right" /> Air
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Inland">
                          <span className="fa fa-angle-double-right" /> Inland
                          Freight <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Warehousing">
                          <span className="fa fa-angle-double-right" />{" "}
                          Warehousing & Distributions <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Customs">
                          <span className="fa fa-angle-double-right" /> Customs
                          Brokerage <label></label>
                        </Link>
                      </li>
                      <li>
                        <Link to="/Value">
                          <span className="fa fa-angle-double-right" /> Value
                          Added Services <label></label>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
