import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ minHeight: "300px" }}
          >
            <h3 className="display-4 text-white text-uppercase">
              Multimodal Transportation
            </h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" t="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">Multimodal Transportation</p>
            </div>
          </div>
        </div>
      </div>
      {/* Detail Start */}
      <div className="container-fluid py-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-5">
                <h1 className="mb-5">Multimodal Transportation</h1>
                <img
                  className="img-fluid rounded w-100 mb-4"
                  src="img/Multimodal Transportation.jpg"
                  alt="Image"
                />
                <p>
                  <p>
                    When transportation is seamlessly managed by a single
                    organization, your company can unlock substantial time and
                    cost savings. Tap into our extensive network and expertise
                    to access the perfect blend of multimodal transportation
                    solutions.
                  </p>

                  <p>
                    Our versatile inland transportation services cater to both
                    FCL (full container load) and LCL (less than full container
                    load) shipments via rail, barge, and road, ensuring that
                    your goods are collected and delivered precisely as
                    required.
                  </p>

                  <p>
                    Our team is proficient in determining the most efficient
                    routes, handling paperwork, coordinating schedules, and
                    meeting regulatory requirements, thereby expanding the
                    possibilities for all types of cargo.
                  </p>

                  <p>
                    Choose Ironbark Enterprises Logistics as your integrated
                    solution services provider for all your multimodal
                    transportation needs.
                  </p>
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="mb-5">
                <h3
                  className="text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Categories
                </h3>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Sea" className="text-decoration-none h6 m-0">
                      Sea Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Railway" className="text-decoration-none h6 m-0">
                      Railway Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Container"
                      className="text-decoration-none h6 m-0"
                    >
                      Container Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link
                      to="/Multimodal"
                      className="text-decoration-none h6 m-0"
                    >
                      Multimodal Transportation
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Freight" className="text-decoration-none h6 m-0">
                      Freight Forwarding
                    </Link>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    <Link to="/Trading" className="text-decoration-none h6 m-0">
                      Trading
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Detail End */}

      <Footer />
    </>
  );
}
