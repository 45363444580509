import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">Services</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                Services{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-news-sec">
        <div className="news-mainhny py-5">
          <div className="container text-center py-lg-3">
            <div className="title-content text-center mb-lg-5 mb-4">
              <h6 className="sub-titlehny">Our Services</h6>
              <h3 className="hny-title">Explore our Services</h3>
              <div className="separatorhny" />
            </div>
          </div>
          <div className=" row p-2  ">
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Ocean">
                  <img
                    src="assets/images/Ocean Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Ocean">
                    <h4 className="title">Ocean Freight</h4>
                  </Link>
                  <Link to="/Ocean" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Air">
                  <img
                    src="assets/images/Air Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Air">
                    <h4 className="title">Air Freight</h4>
                  </Link>
                  <Link to="/Air" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Inland">
                  <img
                    src="assets/images/Inland Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Inland">
                    <h4 className="title">Inland Freight</h4>
                  </Link>
                  <Link to="/Inland" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className=" row p-2 my-4 ">
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Warehousing">
                  <img
                    src="assets/images/Warehousing & Distributions.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Warehousing">
                    <h4 className="title">Warehousing & Distributions</h4>
                  </Link>
                  <Link to="/Warehousing" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Customs">
                  <img
                    src="assets/images/Customs Brokerage.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Customs">
                    <h4 className="title">Customs Brokerage</h4>
                  </Link>
                  <Link to="/Customs" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Value">
                  <img
                    src="assets/images/Value Added Services.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Value">
                    <h4 className="title">Value Added Services</h4>
                  </Link>
                  <Link to="/Value" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
