import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = (
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
) => {
  return (
    <>
      {" "}
      {/*header*/}
      <section className="w3l-header">
        <header id="site-header" className>
          <section className="w3l-top-header py-1">
            <div className="container">
              <div className="d-grid main-top">
                <div className="top-header-left">
                  <ul className="info-top-gridshny">
                    <li className="info-grid">
                      <div className="info-icon">
                        <span className="fa fa-mobile" />
                      </div>
                      <div className="info-text">
                        <p>{companynumber}</p>
                      </div>
                    </li>
                    <li className="info-grid">
                      <div className="info-icon">
                        <span
                          className="fa fa-envelope"
                          style={{ fontSize: "25px" }}
                        />
                      </div>
                      <div className="info-text">
                        <p>{companyemail}</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="top-header-right text-lg-right d-flex">
                  <li className="info-grid">
                    <div className="info-text">
                      <Link to="/Privacy">
                        <p>Privacy </p>
                      </Link>
                    </div>
                  </li>
                  <li className="info-grid">
                    <div className="info-text">
                      <Link to="/Terms">
                        <p>Terms </p>
                      </Link>
                    </div>
                  </li>
                </div>
              </div>
            </div>
          </section>
          <div className="header-2hny py-3">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-dark stroke">
                <h1>
                  <Link className="navbar-brand mr-xl-5" to="/">
                    <img
                      src="assets/images/logo.png"
                      style={{ height: "80px" }}
                    ></img>
                  </Link>
                </h1>
                {/* if logo is image enable this   
  <Link class="navbar-brand" to="#index.html">
      <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
  </Link> */}
                <button
                  className="navbar-toggler  collapsed bg-gradient"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon fa icon-expand fa-bars" />
                  <span className="navbar-toggler-icon fa icon-close fa-times" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarTogglerDemo02"
                >
                  <ul className="navbar-nav mx-lg-auto">
                    <li className="nav-item active">
                      <Link className="nav-link" to="/">
                        Home <span className="sr-only">(current)</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/About">
                        About Company
                      </Link>
                    </li>
                    
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="navbarDropdown1"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Our Services
                        <span className="fa fa-angle-down" />
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown1"
                      >
                        <Link className="dropdown-item" to="/Ocean">
                        Ocean Freight
                        </Link>
                        <Link className="dropdown-item" to="/Air">
                        Air Freight
                        </Link>
                        <Link className="dropdown-item" to="/Inland">
                        Inland Freight
                        </Link>
                        <Link className="dropdown-item" to="/Warehousing">
                        Warehousing & Distributions
                        </Link>
                        <Link className="dropdown-item" to="/Customs">
                        Customs Brokerage
                        </Link>
                        <Link className="dropdown-item" to="/Value">
                        Value Added Services
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="navbarDropdown1"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Industries
                        <span className="fa fa-angle-down" />
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown1"
                      >
                        <Link className="dropdown-item" to="/Trading">
                        Automotive
                        </Link>
                        <Link className="dropdown-item" to="/Trading">
                        Healthcare
                        </Link>
                        <Link className="dropdown-item" to="/Trading">
                        Manufacturing & Industrial
                        </Link>
                        <Link className="dropdown-item" to="/Trading">
                        Retail & Consumer
                        </Link>
                       
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                  <ul className="navbar-nav search-right mt-lg-0 mt-2">
                   
                    <li className="nav-item">
                      <Link
                        to="#download"
                        className="free-con btn btn-style btn-secondary d-block"
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        Request A Quote
                      </Link>
                   
                      <Getquote />
                     
                    </li>
                  </ul>
                
                </div>
             
              </nav>
            </div>
          </div>
        </header>
        {/*/header*/}
      </section>
    </>
  );
};

export default Header;
