import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <section
        className="container-fluid p-0  mb-5 w3-banner jarallax"
        style={{ paddingTop: "100px" }}
        id="home"
      >
        <video autoPlay loop muted>
          <source src="assets/images/video.mp4"></source>
        </video>
      </section>

      {/*/grids*/}
      <section className="w3l-grids-3 py-3" id="about">
        <div className="container py-md-5 py-3">
          <div className="row bottom-ab-grids align-items-center">
            <div className="col-lg-6 bottom-ab-left">
              <h6 className="sub-titlehny">About Us</h6>
              <h3 className="hny-title">{companyname} Go Beyond</h3>
              <div className="separatorhny" />
              <p className="my-3">
                The company {companyname} is headquartered in India. In the
                Indian market, we are a newly established and rapidly growing
                freight forwarding company. We have consistently exceeded the
                expectations of our domestic clients. Recognizing the pivotal
                role that shipping and transportation play in our daily lives,
                our primary recruitment strategy is to seek proactive
                individuals with expertise in aviation, liner, and freight
                forwarding.
              </p>
              <Link to="/About" className="btn btn-style btn-primary mt-4">
                Read More
              </Link>
            </div>
            <div className="col-lg-6 bottom-ab-right mt-lg-0 mt-5 pl-lg-4">
              <img src="assets/images/About.jpg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      {/*//grids*/}
      {/*/features*/}
      <section className="w3l-ab-features">
        <div className="container py-md-5 ">
          <div className="row features-w3pvt-main" id="features">
            <div className="col-lg-4 col-md-6 feature-gird">
              <div className="row features-hny-inner-gd">
                <div className="col-md-2 col-2 featured_grid_left">
                  <div className="icon-hnynumber">
                    <span className="hnynumber">01</span>
                  </div>
                </div>
                <div className="col-md-10 col-10 featured_grid_right_info">
                  <h4>
                    <Link className="link-hny">CLARITY</Link>
                  </h4>
                  <p>
                    Our operational process involves scrutinizing the entire
                    data flow, starting from the product and extending to the
                    customer's final steps.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 feature-gird mt-md-0 mt-4">
              <div className="row features-hny-inner-gd">
                <div className="col-md-2 col-2 featured_grid_left">
                  <div className="icon-hnynumber">
                    <span className="hnynumber">02</span>
                  </div>
                </div>
                <div className="col-md-10 col-10 featured_grid_right_info">
                  <h4>
                    <Link className="link-hny">DILIGENCE</Link>
                  </h4>
                  <p>
                    We proactively identify and address opportunities and
                    challenges, staying ahead of potential issues.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 feature-gird mt-lg-0 mt-md-5 mt-4">
              <div className="row features-hny-inner-gd">
                <div className="col-md-2 col-2 featured_grid_left">
                  <div className="icon-hnynumber">
                    <span className="hnynumber">03</span>
                  </div>
                </div>
                <div className="col-md-10 col-10 featured_grid_right_info">
                  <h4>
                    <Link className="link-hny">VERSATILITY</Link>
                  </h4>
                  <p>
                    Our extensive global network of partners and suppliers
                    allows us to swiftly adapt and navigate various logistical
                    challenges with ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      {/* //blog-Section */}
      {/*//news-grids*/}
      <section className="w3l-news-sec">
        <div className="news-mainhny py-5">
          <div className="container text-center py-lg-3">
            <div className="title-content text-center mb-lg-5 mb-4">
              <h6 className="sub-titlehny">Our Services</h6>
              <h3 className="hny-title">Explore our Services</h3>
              <div className="separatorhny" />
            </div>
          </div>
          <div className=" row p-2  ">
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Ocean">
                  <img
                    src="assets/images/Ocean Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Ocean">
                    <h4 className="title">Ocean Freight</h4>
                  </Link>
                  <Link to="/Ocean" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Air">
                  <img
                    src="assets/images/Air Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Air">
                    <h4 className="title">Air Freight</h4>
                  </Link>
                  <Link to="/Air" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Inland">
                  <img
                    src="assets/images/Inland Freight.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Inland">
                    <h4 className="title">Inland Freight</h4>
                  </Link>
                  <Link to="/Inland" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className=" row p-2 my-4 ">
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Warehousing">
                  <img
                    src="assets/images/Warehousing & Distributions.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Warehousing">
                    <h4 className="title">Warehousing & Distributions</h4>
                  </Link>
                  <Link to="/Warehousing" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Customs">
                  <img
                    src="assets/images/Customs Brokerage.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Customs">
                    <h4 className="title">Customs Brokerage</h4>
                  </Link>
                  <Link to="/Customs" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="item col-lg-4">
              <div className="news-img position-relative">
                <Link to="/Value">
                  <img
                    src="assets/images/Value Added Services.jpg"
                    className="img-fluid"
                    alt="news image"
                  />
                </Link>
                <div className="title-wrap">
                  <Link to="/Value">
                    <h4 className="title">Value Added Services</h4>
                  </Link>
                  <Link to="/Value" className="text-right">
                    <span className="fa fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*//news-grids*/}
      <div className="w3l-faq-block py-5" id="faq">
        <div className="container py-md-5">
          <div className="row w3l-faq">
            <div className="faq-page col-lg-6 mb-lg-0 mb-4">
              <div className="text-left mb-4">
                <h6 className="sub-titlehny">
                  Why Choose Us
                </h6>
                <h3 className="hny-title">Synchronized Shipping, Every Time, We Offer..
                </h3>
                <div className="separatorhny" /> 
              </div>
              <ul>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <h4>Our Mission</h4>
                  <p>At the core of our mission is a dedicated commitment to meeting our promises and delivering exceptional value-added services to our esteemed clients. We aspire to pioneer new benchmarks in quality, reliability, and consistency within our industry. By upholding the principles of integrity, reliability, and a steadfast commitment to meeting deadlines, we aim to cultivate enduring and mutually beneficial relationships with both our valued partners and clients.</p>

                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <h4>Our Vision</h4>
                  <p>Our vision is to become a recognized leader in the logistics sector, supported by a robust agent network and a loyal and growing clientele. We are driven to optimize your logistics efficiency as a comprehensive omnichannel service provider. Our unwavering dedication is to provide unparalleled service and relentlessly pursue excellence, positioning us as the ultimate and dependable goods forwarder in the industry.</p>
                </li>
               
              </ul>
            </div>
            <div className="faq-img-right col-lg-6 pl-lg-5">
              <img src="assets/images/Warehousing & Distributions.jpg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <section className="w3l-team py-5" id="team">
        <div className="container py-lg-4 py-sm-3">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="header-sec text-center">
            <h6 className="sub-titlehny text-center">Industries</h6>
            <h3 className="hny-title text-center">Our Industry Solutions</h3>
            <div className="separatorhny" />
          </div>
          <div className="row text-center mt-5">
            <div className="col-md-3 col-6">
              <div className="team-grids text-center">
                <img
                  src="assets/images/Automotive.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="team-info">
                  <div className="caption">
                    <div className=" text-center">
                      <Link
                        to="/Trading"
                        className="btn btn-style btn-primary mt-4"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <h4>
                <Link to="/Trading">Automotive</Link>
              </h4>
            </div>
            <div className="col-md-3 col-6">
              <div className="team-grids text-center">
                <img
                  src="assets/images/Healthcare.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="team-info">
                  <div className="caption">
                    <div className=" text-center">
                      <Link
                        to="/Trading"
                        className="btn btn-style btn-primary mt-4"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <h4>
                <Link to="/Trading">Healthcare</Link>
              </h4>
            </div>
            <div className="col-md-3 col-6">
              <div className="team-grids text-center">
                <img
                  src="assets/images/Manufacturing & Industrial.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="team-info">
                  <div className="caption">
                    <div className=" text-center">
                      <Link
                        to="/Trading"
                        className="btn btn-style btn-primary mt-4"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <h4>
                <Link to="/Trading">Manufacturing & Industrial</Link>
              </h4>
            </div>
            <div className="col-md-3 col-6">
              <div className="team-grids text-center">
                <img
                  src="assets/images/Retail & Consumer.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div className="team-info">
                  <div className="caption">
                    <div className=" text-center">
                      <Link
                        to="/Trading"
                        className="btn btn-style btn-primary mt-4"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <h4>
                <Link to="/Trading">Retail & Consumer</Link>
              </h4>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
