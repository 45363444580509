import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-about-breadcrumb">
        <div className="breadcrumb-bg breadcrumb-bg-about py-5">
          <div className="container">
            <h2 className="title">Industry Solutions</h2>
            <ul className="breadcrumbs-custom-path mt-3 text-center">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                <span className="fa fa-arrow-right mx-2" aria-hidden="true" />{" "}
                Industry Solutions{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-grids-3 py-3" id="about">
        <div className="container py-md-5 py-3">
          <div className="row bottom-ab-grids align-items-center">
            <div className="col-lg-6 bottom-ab-left">
              <h6 className="sub-titlehny">Industry Solutions</h6>
              <h3 className="hny-title">Automotive</h3>
              <div className="separatorhny" />
              <p className="my-3">
                At {companyname}, we boast an exceptional team with vast
                expertise in the automotive sector. Our commitment to innovation
                and top-notch quality ensures that we provide world-class
                automotive services and solutions to our clients, setting new
                industry standards.
              </p>
            </div>
            <div className="col-lg-6 bottom-ab-right mt-lg-0 mt-5 pl-lg-4">
              <img
                src="assets/images/Automotive.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="container py-md-5 py-3">
          <div className="row bottom-ab-grids align-items-center">
            <div className="col-lg-6 bottom-ab-right mt-lg-0 mt-5 pl-lg-4">
              <img
                src="assets/images/Healthcare.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 bottom-ab-left">
              <h6 className="sub-titlehny text-right">Industry Solutions</h6>
              <h3 className="hny-title text-right">Healthcare</h3>
              <div className="text-right">
                <div className="separatorhny text-right" />
              </div>
              <p className="my-3">
                With a profound understanding of the healthcare industry, gained
                through close collaboration with leading healthcare
                organizations, {companyname} is well-versed in the
                precision-driven demands of this sector. From meticulous process
                mapping to strategic logistics planning, our unwavering focus on
                quality and continuous improvement is paramount.
              </p>
            </div>
          </div>
        </div>
        <div className="container py-md-5 py-3">
          <div className="row bottom-ab-grids align-items-center">
            <div className="col-lg-6 bottom-ab-left">
              <h6 className="sub-titlehny">Industry Solutions</h6>
              <h3 className="hny-title">Manufacturing & Industrial</h3>
              <div className="separatorhny" />
              <p className="my-3">
                {" "}
                We invest considerable effort in comprehending the specific
                requirements of each client within the manufacturing and
                industrial realms. Recognizing the uniqueness of every business,
                we tailor custom solutions to meet their international logistics
                needs, ensuring efficient and effective operations.
              </p>
            </div>
            <div className="col-lg-6 bottom-ab-right mt-lg-0 mt-5 pl-lg-4">
              <img
                src="assets/images/Manufacturing & Industrial.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="container py-md-5 py-3">
          <div className="row bottom-ab-grids align-items-center">
            <div className="col-lg-6 bottom-ab-right mt-lg-0 mt-5 pl-lg-4">
              <img
                src="assets/images/Retail & Consumer.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 bottom-ab-left">
              <h6 className="sub-titlehny text-right">Industry Solutions</h6>
              <h3 className="hny-title text-right">Retail & Consumer</h3>
              <div className="text-right">
                <div className="separatorhny text-right" />
              </div>
              <p className="my-3">
                {companyname} has a proven track record of delivering
                exceptional value to clients across diverse market categories
                within the retail and consumer industries. Our tailored
                solutions cater to the specific needs of businesses in these
                sectors, promoting growth and success.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
